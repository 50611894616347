<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('masterdata_new_dairy_header')"></h1>

    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>

    <v-form ref="createDairiesForm" id="createDairiesForm">
      <v-row class="dairy-form">
        <v-col cols="12" xs="12" md="6" xl="4">
          <!-- Dairy Type ID -->
          <v-select
            v-model="dairies.dairyType"
            :label="$t('dairies_dairytype')"
            :items="dairyTypes"
            :item-title="this.$getLangKey()"
            item-value="name"
            :rules="[rules.required]"
          />
          <!-- Zuständiger MA TSM -->
          <v-select
            v-model="dairies.responsibilityGroup"
            :label="$t('dairies_personid')"
            :items="responsibilityGroups"
            :item-title="this.$getLangKey()"
            item-value="name"
            :rules="[rules.required]"
          />
          <!-- BUR -->
          <v-text-field id="bur" v-model="dairies.berNo" :label="$t('dairies_berno')" type="string" :rules="[rules.required]" />
          <!-- EGID -->
          <v-text-field id="egid" v-model="dairies.egid" :label="$t('dairies_egid')" type="number" />
          <!-- Betriebszulassungsnummer -->
          <v-text-field id="permitNo" v-model="dairies.permitNo" :label="$t('dairies_farm_permit_number')" type="string" />
          <!-- AGIS-ID -->
          <v-text-field id="bur" v-model="dairies.agisId" :label="$t('dairies_agisid')" type="number" />
          <!-- Kanton -->
          <v-select
            id="cantonNo"
            v-model="dairies.canton.id"
            :label="$t('dairies_canton_no')"
            :items="cantonsCH"
            :item-title="this.$getLangKey()"
            item-value="id"
            clearable
            :rules="[rules.required]"
          />
          <!-- Production Reporting obligation -->
          <v-select
            id="production_reporting_obligation"
            v-model="dairies.productionReportingObligation"
            :label="$t('dairies_reporting_obligation_MPD1')"
            :items="reportingObligations"
            :item-title="this.$getLangKey()"
            item-value="name"
            :rules="[rules.required]"
          />
          <!-- Processing Reporting obligation -->
          <v-select
            id="processing_reporting_obligation"
            v-model="dairies.processingReportingObligation"
            :label="$t('dairies_reporting_obligation_TSM1')"
            :items="reportingObligations"
            :item-title="this.$getLangKey()"
            item-value="name"
            :rules="[rules.required]"
          />
          <!-- segmentationReportingObligation-->
          <v-select
            id="segmentation_reporting_obligation"
            v-model="dairies.segmentationReportingObligation"
            :label="$t('dairies_segmentation_reporting_obligation')"
            :items="reportingObligations"
            :item-title="this.$getLangKey()"
            item-value="name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <v-btn class="m-2" color="primary" @click="onSubmit"><span v-html="$t('dairies_new_dairy_save')" /></v-btn>
      <v-btn class="m-2" color="secondary" :to="{ name: 'masterdata_dairies_search' }" @click="reset">
        <span v-html="$t('dairies_new_dairy_cancel')" />
      </v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { useCantonsStore } from '@/store/enums/CantonsStore'
import { useDairyTypesStore } from '@/store/enums/DairyTypesStore'
import type { VForm } from 'vuetify/components'
import type { components } from '@/lib/api/v1.d.ts'
type Dairy = components['schemas']['Dairy']
type Person = components['schemas']['Person']
type Canton = components['schemas']['Canton']
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

const dairyDataDefault: Dairy = {
  ident: 0,
  berNo: '',
  egid: undefined,
  permitNo: '',
  agisId: undefined,
  canton: { id: undefined } as Canton,
  productionReportingObligation: undefined,
  processingReportingObligation: undefined,
  segmentationReportingObligation: undefined,
  responsibilityGroup: undefined,
  tsmOffice: 'BERN',
  agisClearing: false,
  casalp: false,
  bom: false,
  smp: false,
  marketStabilisation: false,
  person: {} as Person
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dairiesCreate',
  data() {
    return {
      dairies: { ...dairyDataDefault } as Dairy,
      rules: {
        required: (value: any) => !!value || this.$t('form_field_required')
      },
      loading: false
    }
  },
  computed: {
    dairyTypes() {
      return useDairyTypesStore().dairyTypes
    },
    cantonsCH() {
      return useCantonsStore().CantonsCH
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    },
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    }
  },
  methods: {
    reset() {
      ;(this.$refs.createDairiesForm as VForm).reset()
    },
    assignTsmOffice() {
      if (
        this.dairies.responsibilityGroup == 'LE_MONT_1' ||
        this.dairies.responsibilityGroup == 'LE_MONT_2' ||
        this.dairies.responsibilityGroup == 'LE_MONT_3'
      ) {
        this.dairies.tsmOffice = 'LAUSANNE'
      } else {
        this.dairies.tsmOffice = 'BERN'
      }
    },
    async onSubmit() {
      if (!(await (this.$refs.createDairiesForm as VForm).validate()).valid) {
        return false
      }
      this.assignTsmOffice()
      this.loading = true
      try {
        const response = await this.axios.post(
          apiURL + '/dairies',
          {
            ...this.dairies
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await response) {
          const data = await response.data
          this.$router
            .push({
              name: 'masterdata_dairies_edit',
              params: { id: data.id, tab: 'overview' }
            })
            .catch((e) => {
              /* duplicated route */
            })
          this.reset()
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    }
  },
  beforeMount() {
    useDairyTypesStore().fetchDairyTypes()
    useCantonsStore().fetchCantonsCH()
    useResponsibilityGroupsStore().fetchResponsibilityGroups()
    useReportingObligationsStore().fetchReportingObligations()
  }
})
</script>
